<template>
  <div class="portfolio-page">
    <Page-header
      :cover="require('../assets/images/portfolio-cover.png')"
      :title="$t('solutions')"
    />
    <!-- :desc="$t('testTxt')" -->
    <div class="container">
      <div class="solutions-section page">
        <Solution-card
          v-for="(solution, solutionIndex) in solutions"
          :key="solutionIndex"
          :solution="solution"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SolutionCard from "../components/Layout/SolutionCard.vue";
import PageHeader from "../components/misc/PageHeader.vue";

export default {
  components: { PageHeader, SolutionCard },
  computed: {
    ...mapGetters(["solutions"]),
  },
};
</script>
=